// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Common styling for the content area, as opposed to the Navbar.
//
// This is intended to be used by containers (src/containers/) to wrap
// the content area, providing standard page margins.
//
// The default Bootstrap containers are often unsuitable, because they set
// a maximum width, and sometimes we really do want to use the whole screen.

$vertical-margin: 17px;
$horizontal-margin: 20px;

div.contentArea {
  margin: $vertical-margin $horizontal-margin $vertical-margin $horizontal-margin;
}
