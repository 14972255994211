// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

.MainDiv {
  display: "flex";
  flex-direction: "column";
}

.OplIframe {
  min-height: 500px;
}

.CardHeader {
  font-weight: bold;
  font-size: 25px;
}

.NotesSection {
  white-space: "pre-wrap";
  font-size: 16px
}

.LeftCardDeck {
  display: "flex";
  flex-direction: "column";
  margin-right: 15px;
}

