// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

$leftMargin: 5px;
$TitleBackgroundColour:black;
$BestLiftBackgroundColour: black;
$BestLiftForegroundColour: white;
$TitleColour:white;
$ContentAreaBackgroundColour: #eeeeee;
$ContentAreaTextColour: black;

.MainContainer {
  color: #10202B;
  border-right: solid;
  border-color: white;
}

.ContentAreaContainer {
  background-color: $ContentAreaBackgroundColour;
  color: $ContentAreaTextColour;
  display: flex;
  flex: 1;
}

.MainContentAreaContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}

.Container {
  font-size: 20px;
  width: 660px;
  height: 250px;
  display: flex;
}

.TitleContainer {
/*   border-bottom-color: black;
  border-bottom-style: solid; */
  //border-bottom-width: 1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: $TitleBackgroundColour;
  color: $TitleColour;
  height: 60px;

}

.LifterName {
  margin-left: $leftMargin;
  /*border-bottom-color: black;
  border-bottom-style: solid;*/
  font-size: 35px;
  /* -webkit-text-stroke: 0.5px black; /* width and color */
}

.LifterSubTitle {
  margin-left: $leftMargin;
  padding: 10px;
  font-size: 20px;
  border-radius: 50px;
  color:white;
  background-color: black;
}

.CurrentLiftType {
  margin-left: $leftMargin;
  font-size: 35px;
}

.LiftResultBubble {
  //width: 170px;
  flex: 1;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 50px;
  color: white;
}

.CurrentLiftParentContainer {
  //margin-top: 30px;
}

.CurrentLiftContainer {
  display: flex;
  margin-left: $leftMargin;
}

.BestLiftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
 /* border-left: solid;*/
  width: 110px;
  background-color: $BestLiftBackgroundColour;
  color: $BestLiftForegroundColour;
  &Active {
    @extend .BestLiftContainer;
    background-color: #505050;
  }
}


$recordNotificationAnimFromColor: black;
$recorNotificationdAnimToColor: rgb(209, 4, 55);

.RecordAttemptNotice {
  -webkit-animation: record_color_change 1s infinite alternate;
  -moz-animation: record_color_change 1s infinite alternate;
  -ms-animation: record_color_change 1s infinite alternate;
  -o-animation: record_color_change 1s infinite alternate;
  animation: record_color_change 1s infinite alternate;
}

 @-webkit-keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }
 @-moz-keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }
 @-ms-keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }
 @-o-keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }
 @keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }

$currentLiftRecordFromColor: white;
$currentLiftRecordToColor: red;

.CurrentLiftAnimate {
  -webkit-animation: current_lift_record_color_change 1s infinite alternate;
  -moz-animation: current_lift_record_color_change 1s infinite alternate;
  -ms-animation: current_lift_record_color_change 1s infinite alternate;
  -o-animation: current_lift_record_color_change 1s infinite alternate;
  animation: current_lift_record_color_change 1s infinite alternate;
}

 @-webkit-keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
 @-moz-keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
 @-ms-keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
 @-o-keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
 @keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }