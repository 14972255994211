// vim: set ts=2 sts=2 sw=2 et:
// Bootstrap customization for OpenLifter.

// Decrease the default font size throughout the whole application.
$font-size-base: 0.75rem;
$font-size-navbar: 0.875rem;

// Disable validation icons. There is currently a bug in node-sass 4.12.0
// that invalidates their background-position attribute in release builds,
// causing the icons to display way too far on the right.
$enable-validation-icons: false;

// Configuration of the color system.
$body-bg: #dfe4e6; // A darker white (~87%) to make the foreground pop.

// Buttons and highlights and such.
$primary: #007bff; // $blue.
$secondary: #adb5bd; // $gray-500.
$success: #28a745; // $green.
$info: #5bc0de; // The info color from version 1.0 (using Bootstrap 3).
$warning: #ffc107; // $yellow.
$danger: #dc3545; // $red.
$light: #f8f9fa; // $gray-100.
$dark: #343a40; // $gray-800.

// Bootstrap button logic determines the text color from the background color
// by deciding whether the background is "light" or "dark". We want it to always
// use a white font color, even if the background is light.
.btn-info {
  color: #fff !important;
}
.btn-warning {
  color: #fff !important;
}

// Bootstrap 4 allows marking elements as valid or invalid by setting
// "is-valid" and "is-invalid" CSS classes. Unfortunately, they deleted
// the "is-warning" option. So we add it back manually here.
//
// Overriding $form-validation-states doesn't seem to work because it's the
// .form-control:valid selector that makes something valid -- and there are
// only :valid and :invalid selectors in CSS, nothing like :warning.
// So if we override that, the selector unfortunately never matches.
//
// So we just make our own CSS "is-warning" class work, ignoring all the
// .was-validated nonsense. It's just a color change, anyway.
$form-feedback-warning-color: #8a6d3b;
.form-control.is-warning {
  border-color: $form-feedback-warning-color;
}
.form-control.is-warning:focus {
  border-color: $form-feedback-warning-color;
  box-shadow: 0 0 0 .2rem rgba($form-feedback-warning-color, .25);
}

// Custom styling for attempt inputs, since their borders are hidden.
// This makes it way more obvious when a value is "weird but allowed."
$form-feedback-warning-background-color: #ffffc9; // Pale yellow.
.attempt-input.is-warning {
  border-color: #ced4da; // The normal default border color.
  background-color: $form-feedback-warning-background-color;
}

// Custom styling for the Navbar at the top of the screen.
.navbar {
  padding: 0.25rem 1rem !important; // Decrease vertical padding.
}

// Custom styling for the link to the current page in the Navbar.
a.nav-link {
  font-size: $font-size-navbar;
}
a.nav-link.active {
  font-weight: bold;
}
