.Container {
	height: 100%;
	//opacity: 0.9;
	min-width: 250px;
	font-size: 25px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	float: left;
}

.Table {
	align-self: flex-end;
	width: 100%;
}

.RowEven {
	background-color: black;
	color: white;
}

.RowOdd {
	background-color: white;
	color: black;
}

.RowContent {
	margin-left: 5px;
	margin-right: 5px;
}

.TableHeader {
	background-color: #333333;
	color: #F6F6F6;
}