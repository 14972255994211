// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Theming for the control bar at the bottom of the screen.

$color-dark: #232323;
$color-light: #ffffff;

$button-width: 200px;

// Container element of the whole footer.
div.footer {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
}

div.leftControls {
  display: flex;
  align-items: center;
  padding-left: 4px;
}

div.rightControls {
  display: flex;
  align-items: center;
}

button.fullscreen {
  margin-right: 7px;
}

button.goodLift {
  width: $button-width;
  height: 100%;
  padding: 10px 16px;

  font-size: 18px;
  line-height: 1.3333333;

  color: #ffffff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  border-width: 0px;
}
button.goodLift:hover {
  background-color: #31d800;
}

button.noLift {
  width: $button-width;
  height: 100%;
  padding: 10px 16px;

  font-size: 18px;
  line-height: 1.3333333;

  color: #ffffff;
  background-color: #ae423f;
  border-color: #d43f3a;
  border-width: 0px;
}
button.noLift:hover {
  background-color: #d9534f
}

select.selector {
  width: 110px;
}
